import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import logo from "images/gf365-logo.svg";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends Component {
  static propTypes = {
    global: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    //console.log(error, errorInfo);
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        error: false
      });
    }
  }

  render() {
    const { global, children } = this.props;

    const ErrorReportBtn = ({ color }) => (
      <Button
        color={color}
        className="d-inline-block"
        onClick={() => {
          Sentry.showReportDialog({ eventId: this.state.eventId });
        }}
      >
        Send feedback
      </Button>
    );

    const errorMsg =
      "An error occured in the application. Please try to reload the page or send us feedback if the problem persist.";

    const ReloadBtn = () => (
      <Button
        color="secondary"
        className="d-inline-block"
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload the page
      </Button>
    );
    const GlobalError = () => (
      <>
        <img
          src={logo}
          alt="Greenfee365"
          style={{ width: 100, height: "auto" }}
        />
        <h1 className="mt-4">Whoops!</h1>
        <p>{errorMsg}</p>
        <div>
          <ReloadBtn />
          <ErrorReportBtn color="link" />
        </div>
      </>
    );

    const LocalError = () => (
      <>
        <FontAwesomeIcon
          icon="exclamation-triangle"
          className="text-danger d-block mx-auto mb-2 fa-2x"
        />
        <p className="mb-3">{errorMsg}</p>
        <div>
          <ReloadBtn />
          <ErrorReportBtn color="link" />
        </div>
      </>
    );

    if (this.state.error) {
      return (
        <div
          className={`my-6 container restricted-width-md d-flex flex-column text-center align-items-center ${
            global ? "" : "Body justify-content-center"
          }`}
        >
          {global ? <GlobalError /> : <LocalError />}
        </div>
      );
    } else return children;
  }
}

export default withRouter(ErrorBoundary);
