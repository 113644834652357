import { CheckoutTracker } from "common/CheckoutTracker";
import { ClubTracker } from "common/ClubTracker";

/**
 * Service class to pass data to and trigger events in Google Tag Manager
 */
class GoogleTagManagerService {
  constructor() {
    window.dataLayer = window.dataLayer || [];
    this.checkoutTracker = new CheckoutTracker();
    this.clubTracker = new ClubTracker();
  }

  /**
   * Track visit to a club page
   * @param {Object} club Club object
   */
  clubPageView(club) {
    this.clubTracker.clubPageView(club);
  }

  /**
   * Push an shopping cart update to data layer
   * @param {string} event "addToCard" or "removeFromCart"
   * @param {*} item Item to add or remove
   */
  registerCartChange(event, item) {
    if (event !== "addToCart" && event !== "removeFromCart") {
      // console.warn(`${event} not a valid tracking event.`);
      return;
    }

    let products = [];
    for (let rate of item.appliedRates) {
      products.push({
        name: item.club.name,
        id: item.club._id,
        price: rate.price,
        variant: rate.rateType,
        quantity: 1
      });
    }

    let ecomEvent;
    if (event === "addToCart") {
      ecomEvent = "add";
      this.checkoutTracker.addToCart(products);
    } else {
      ecomEvent = "remove";
      this.checkoutTracker.removeFromCart(products);
    }

    window.dataLayer.push({
      event: event,
      ecommerce: {
        [ecomEvent]: {
          products
        }
      }
    });
  }

  checkoutStart() {
    this.checkoutTracker.checkoutStart();
  }
  /**
   * User has clicked the "Place order" button
   * @param {{ newsletter: boolean, guest: boolean}} options
   */
  checkoutAttempt(options) {
    this.checkoutTracker.checkoutAttempt(options);
  }
  orderSuccess(orderId) {
    this.checkoutTracker.purchase(orderId);
  }
}

export default new GoogleTagManagerService();
