// Vendor
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Navbar, NavItem } from "reactstrap";

// App
import Auth from "common/Auth";
import CartStorage from "common/CartStorage";
import EventEmitterClient from "common/EventEmitterClient";
import logo from "images/gf365-logo.svg";
import { CHECKOUT_ROUTE, START_ROUTE } from "common/RoutesHelper";
import AutoCompleteSearch from "components/ui/AutoCompleteSearch";
import LanguageDropdown from "components/ui/LanguageDropdown";
import {
  AuthMenuLoggedIn,
  AuthMenuLoggedOut,
  CartIcon
} from "components/ui/FunctionalComponents";
import MobileMenu from "components/app/MobileMenu";
import MobileMenuToggler from "components/app/MobileMenuToggle";

class Header extends Component {
  constructor() {
    super();

    this.state = {
      menuIsOpen: false,
      langMenuOpen: false,
      cartCount: 0,
      hideControls: false,
      showLogin: false,
      showForgotPassword: false,
      showSignup: false,
      loggedIn: Auth.isAuthenticated()
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.hideControls = this.hideControls.bind(this);
    this.showControls = this.showControls.bind(this);
    this.updateCart = this.updateCart.bind(this);
  }

  componentDidMount() {
    EventEmitterClient.on("CART_UPDATE_SUCCESS", this.updateCart);
    EventEmitterClient.on("LOGIN", this.handleLogin);
    EventEmitterClient.on("LOGOUT", this.handleLogout);
    EventEmitterClient.on("SEARCH_FOCUS", this.hideControls);
    EventEmitterClient.on("SEARCH_BLUR", this.showControls);
    this.setState({ cartCount: CartStorage.count() });
  }

  componentWillUnmount() {
    EventEmitterClient.removeEventListener(
      "CART_UPDATE_SUCCESS",
      this.updateCart
    );
    EventEmitterClient.removeEventListener("LOGIN", this.handleLogin);
    EventEmitterClient.removeEventListener("LOGOUT", this.handleLogout);
    EventEmitterClient.removeEventListener("SEARCH_FOCUS", this.hideControls);
    EventEmitterClient.removeEventListener("SEARCH_BLUR", this.showControls);
  }

  handleLogin() {
    this.setState({ loggedIn: true });
  }

  handleLogout() {
    this.setState({ loggedIn: false });
  }

  hideControls() {
    this.setState({ hideControls: true });
  }

  showControls() {
    this.setState({ hideControls: false });
  }

  updateCart() {
    this.setState({ cartCount: CartStorage.count() });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="Header position-relative">
        <Navbar className="d-flex" light expand="md">
          <Link
            to={START_ROUTE.url()}
            className="navbar-brand mr-md-3 mr-2 d-md-inline-block"
          >
            <img src={logo} alt="Greenfee365 logo" />
          </Link>

          <div
            className={`Header__search flex-grow-1 ${
              this.state.hideControls ? "Header__search--focus" : ""
            }`}
            style={{ maxWidth: 500 }}
          >
            <AutoCompleteSearch />
          </div>

          <div className="d-md-none navbar-nav ml-auto flex-row">
            <NavItem>
              <Link
                to={CHECKOUT_ROUTE.url()}
                className="nav-link pl-3 pr-2 text-body"
              >
                <CartIcon theme="dark" count={this.state.cartCount} />
              </Link>
            </NavItem>

            <MobileMenuToggler
              open={this.state.menuIsOpen}
              onChange={menuIsOpen => {
                this.setState({ menuIsOpen });
              }}
            />
          </div>

          <div className="d-none d-md-flex navbar-nav ml-auto">
            <NavItem>
              <Link to={CHECKOUT_ROUTE.url()} className="nav-link">
                <CartIcon theme="dark" count={this.state.cartCount} />
                <span className="ml-1">{t("checkout")}</span>
              </Link>
            </NavItem>
            {this.state.loggedIn ? (
              <>
                <LanguageDropdown
                  alignRight={true}
                  inNavbar={true}
                  showLabel={false}
                />
                <AuthMenuLoggedIn />
              </>
            ) : (
              <>
                <LanguageDropdown
                  alignRight={true}
                  inNavbar={true}
                  showLabel={false}
                />
                <AuthMenuLoggedOut />
              </>
            )}
          </div>
        </Navbar>
        <MobileMenu
          onChange={() => {
            this.setState({ menuIsOpen: false });
          }}
          open={this.state.menuIsOpen}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation()(Header));
