// Vendor
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

// App
import Emitter from "common/EventEmitterClient";
import LocaleHelper from "common/LocaleHelper";
import {
  routesMatch,
  PASSWORD_RESET_ROUTE,
  VERIFY_EMAIL_ROUTE,
  START_ROUTE,
  GOLF_CLUB_ROUTE,
  isHome
} from "common/RoutesHelper";
import Header from "components/app/Header";
import Body from "components/app/Body";
import Footer from "components/app/Footer";
import LoginForm from "components/ui/auth/LoginForm";
import SignupForm from "components/ui/auth/SignupForm";
import ForgotPasswordForm from "components/ui/auth/ForgotPasswordForm";
import { appendScript } from "common/Scripts";
import ErrorBoundary from "components/ui/ErrorBoundary";
import URLHelper from "common/URLHelper";
import PathDetector from "config/detector";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogin: false,
      showForgotPassword: false,
      showSignup: false
    };

    this.handleLocaleChange = this.handleLocaleChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.loadZendesk = this.loadZendesk.bind(this);
    this.loadRecaptcha = this.loadRecaptcha.bind(this);
    this.showForgotPassword = this.showForgotPassword.bind(this);
    this.showLogin = this.showLogin.bind(this);
    this.showSignup = this.showSignup.bind(this);
    this.setOverflow = this.setOverflow.bind(this);
    this.updateLocaleByUrl = this.updateLocaleByUrl.bind(this);
  }

  componentDidMount() {
    // append external scripts to header
    if (!URLHelper.isLocalhost()) {
      this.loadZendesk();
      this.loadRecaptcha();
    }

    // add listeners
    Emitter.on("FORGOT_PASSWORD_SHOW", this.showForgotPassword);
    Emitter.on("LOCALE_CHANGE", this.handleLocaleChange);
    Emitter.on("LOGIN_SHOW", this.showLogin);
    Emitter.on("LOGOUT", this.handleLogout);
    Emitter.on("MOBILE_MENU_CHANGE", this.setOverflow);
    Emitter.on("SIGNUP_SHOW", this.showSignup);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) this.updateLocaleByUrl();
  }

  componentWillUnmount() {
    // Remove listeners on unmount
    Emitter.removeEventListener(
      "FORGOT_PASSWORD_SHOW",
      this.showForgotPassword
    );
    Emitter.removeEventListener("LOCALE_CHANGE", this.handleLocaleChange);
    Emitter.removeEventListener("LOGIN_SHOW", this.showLogin);
    Emitter.removeEventListener("LOGOUT", this.handleLogout);
    Emitter.removeEventListener("MOBILE_MENU_CHANGE", this.setOverflow);
    Emitter.removeEventListener("SIGNUP_SHOW", this.showSignup);
  }

  handleLocaleChange(locale) {
    const { pathname, search } = this.props.location;
    let currentLang = PathDetector.lookup();
    let newLang = locale.languageShort;

    // extract path
    let newPathname = currentLang ? pathname.substring(3) : pathname;

    // full urls
    let oldUrl = `${pathname}${search}`;
    let newUrl = `${newPathname}${search}`;

    // prepend language (if not english)
    if (newLang !== "en") newUrl = `/${newLang}${newUrl}`;

    // go to new newUrl if not same as before
    if (newUrl !== oldUrl) this.props.history.push(newUrl);
  }

  handleLogout(gotoStart) {
    const { location, history } = this.props;
    if (!isHome(location.pathname) && gotoStart) {
      history.push(START_ROUTE.url());
    }
  }

  loadRecaptcha() {
    appendScript(
      "https://www.google.com/recaptcha/api.js",
      "recaptcha-snippet",
      true,
      true
    );
  }

  loadZendesk() {
    const { t } = this.props;
    appendScript(
      "https://static.zdassets.com/ekr/snippet.js?key=0f903061-2745-4bde-a531-010cf94bc510",
      "ze-snippet",
      false,
      true
    )
      .then(() => {
        window.zE(() => window.zE.setLocale("en"));
      })
      .catch(() =>
        Emitter.emit("SYSTEM_ALERT", {
          color: "danger",
          message: t("customer_service_unavailable_text"),
          dismissable: true
        })
      );
  }

  setOverflow(mobileMenuOpen) {
    //Set a timeout so mobile menu icon animation can finish
    setTimeout(() => {
      this.setState({ mobileMenuOpen });
    }, 500);
  }

  showForgotPassword() {
    this.setState({ showForgotPassword: true });
  }

  showLogin() {
    this.setState({ showLogin: true });
  }

  showSignup() {
    this.setState({ showSignup: true });
  }

  updateLocaleByUrl() {
    let newLang =
      PathDetector.lookup() || LocaleHelper.getDefaultLocale().language;
    let currentLang = LocaleHelper.getLocale().language;

    // change lang if the url contains a lang string that differs from the current lang
    if (currentLang !== newLang) LocaleHelper.load(newLang);
  }

  render() {
    const path = this.props.location.pathname;
    const { showLogin, showSignup, showForgotPassword } = this.state;

    const isPasswordReset = routesMatch(path, PASSWORD_RESET_ROUTE.path);
    const isVerifyEmail = routesMatch(path, VERIFY_EMAIL_ROUTE.path);
    const isStart = isHome(path);
    const isGolfCourse = routesMatch(path, GOLF_CLUB_ROUTE.path);

    const hideHeader = isPasswordReset || isVerifyEmail || isStart;
    const hideFooter = isPasswordReset || isVerifyEmail;
    const footerClassName = isGolfCourse ? "mt-0" : "mt-5";

    const { languageShort } = LocaleHelper.getLocale();

    const getLang = lang => (lang === "en" ? "" : `/${lang}`);

    return (
      <div
        className={`Layout ${
          this.state.mobileMenuOpen ? "overflow-hidden position-static" : ""
        }`}
      >
        <Helmet htmlAttributes={{ lang: languageShort }}>
          {!URLHelper.isProduction() && (
            <meta name="robots" content="noindex, nofollow" />
          )}
          {LocaleHelper.getLocales().map((locale, i) => (
            <link
              key={i}
              rel="alternate"
              hrefLang={locale.language}
              href={`https://greenfee365.com${getLang(
                locale.languageShort
              )}${URLHelper.stripLang(path)}`}
            />
          ))}
        </Helmet>

        {showLogin && (
          <LoginForm
            onClose={() => {
              this.setState({ showLogin: false });
            }}
          />
        )}
        {showSignup && (
          <SignupForm
            onClose={() => {
              this.setState({ showSignup: false });
            }}
          />
        )}
        {showForgotPassword && (
          <ForgotPasswordForm
            onClose={() => {
              this.setState({ showForgotPassword: false });
            }}
          />
        )}

        {!hideHeader && <Header />}
        <ErrorBoundary>
          <Body />
        </ErrorBoundary>
        {!hideFooter && <Footer className={footerClassName} />}
      </div>
    );
  }
}

export default withRouter(withTranslation()(Layout));
