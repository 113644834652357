// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

// App
import {
  START_ROUTE,
  USER_PROFILE_ROUTE,
  USER_BOOKINGS_ROUTE
} from "common/RoutesHelper";
import Auth from "common/Auth";
import EventEmitterClient from "common/EventEmitterClient";
import LanguageList from "components/ui/LanguageList";

class MobileMenu extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    open: PropTypes.bool
  };

  static defaultProps = {
    onChange: () => null,
    open: false
  };

  constructor(props) {
    super(props);

    this.state = {
      langMenuOpen: false
    };

    this.closeLangMenu = this.closeLangMenu.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {
    EventEmitterClient.on("MOBILE_MENU_CHANGE", this.closeLangMenu);
  }

  componentWillUnmount() {
    EventEmitterClient.removeEventListener(
      "MOBILE_MENU_CHANGE",
      this.closeLangMenu
    );
  }

  closeLangMenu() {
    this.setState({ langMenuOpen: false });
  }

  handleLinkClick() {
    this.props.onChange();
    EventEmitterClient.emit("MOBILE_MENU_CHANGE", false);
  }

  render() {
    const { t, open } = this.props;
    const { langMenuOpen } = this.state;
    const loggedIn = Auth.getUser();

    const LanguageMenu = () => (
      <>
        <Button
          color="link"
          className="nav-link py-3"
          onClick={() => {
            this.setState(prevState => ({
              langMenuOpen: !prevState.langMenuOpen
            }));
          }}
        >
          {t("language")}
          <FontAwesomeIcon
            icon="angle-down"
            className={`ml-2 ${langMenuOpen ? "flip" : ""}`}
          />
        </Button>
        <ul
          className={`MobileMenu__lang-menu d-flex flex-wrap ${
            langMenuOpen ? "MobileMenu__lang-menu--open" : ""
          }`}
        >
          <div className="w-100">
            <hr className="mx-auto mt-0" />
          </div>
          <LanguageList
            className="nav-link p-2"
            onChange={() => {
              this.handleLinkClick();
              this.setState({ langMenuOpen: false });
            }}
          />
        </ul>
      </>
    );

    return (
      <div className={`MobileMenu fixed-top ${open ? "MobileMenu--open" : ""}`}>
        <div className="d-flex justify-content-center p-4 pt-5 text-center">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                to={START_ROUTE.url()}
                onClick={this.handleLinkClick}
                className="nav-link py-3"
              >
                {t("home")}
              </Link>
            </li>
            {loggedIn && (
              <>
                <li className="nav-item">
                  <Link
                    to={USER_BOOKINGS_ROUTE.url()}
                    onClick={this.handleLinkClick}
                    className="nav-link py-3"
                  >
                    {t("my_bookings")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={USER_PROFILE_ROUTE.url()}
                    onClick={this.handleLinkClick}
                    className="nav-link py-3"
                  >
                    {t("settings")}
                  </Link>
                </li>
                <li className="nav-item">
                  <LanguageMenu />
                </li>
                <li className="nav-item">
                  <Link
                    to="/signup"
                    className="nav-link py-3"
                    onClick={e => {
                      e.preventDefault();
                      this.handleLinkClick();
                      Auth.logout();
                    }}
                  >
                    {t("log_out")}
                  </Link>
                </li>
              </>
            )}
            {!loggedIn && (
              <>
                <li className="nav-item">
                  <Link
                    to="/login"
                    className="nav-link py-3"
                    onClick={e => {
                      e.preventDefault();
                      this.handleLinkClick();
                      EventEmitterClient.emit("LOGIN_SHOW");
                    }}
                  >
                    {t("log_in")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/signup"
                    className="nav-link py-3"
                    onClick={e => {
                      e.preventDefault();
                      this.handleLinkClick();
                      EventEmitterClient.emit("SIGNUP_SHOW");
                    }}
                  >
                    {t("sign_up")}
                  </Link>
                </li>
                <li className="nav-item">
                  <LanguageMenu />
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export const MobileMenuBase = MobileMenu;
export default withTranslation()(MobileMenu);
